import React from "react";
import { useAuth } from "../providers/AuthProvider";

const PrivateHeader = ({ minimal = false, name, diableLogout = false, welcomeText = 'WELCOME', showDetails = false }) => {
    const { user, isAuthenticated, logout } = useAuth();
    const { audition } = user || {};
    const { round, adminName, audLocation, adminDesignation = 'Senior Photographer', meetingLink = '#' } = audition || {};

    return (
        <>
            {minimal ? (
                <>
                    <div className="page-title" style={{ marginBottom: 16 }}>
                        <h3 style={{ fontFamily: 'Inter', fontWeight: 700 }}>
                            {welcomeText}, {name ?? user?.profileName?.toUpperCase()}
                            &nbsp;
                            {!diableLogout && <span className="btn-logout" onClick={logout}>LOGOUT</span>}
                        </h3>
                        {showDetails && (
                            <div className="flex pt-2 flex-row items-center justify-center text-slate-500 uppercase gap-2" style={{ fontSize: 10, fontFamily: 'Inter', fontWeight: 500 }}>
                                <span className="text-uppercase">{audition?.adminName}</span>
                                <span>|</span>
                                <span>{audition?.adminDesignation}</span>
                                <span>|</span>
                                <span>{audition?.mode}</span>
                                <span>|</span>
                                <span>{audition?.audType}</span>
                                <span>|</span>
                                <span>Round {audition?.round}</span>
                                <span>|</span>
                                <span>Commercial</span>
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <div className="flex bg-slate-50 items-center page-title border border-solid border-2 border-slate-100 rounded-md rounded-b-sm p-2" style={{ marginBottom: 8 }}>
                    <div className="flex flex-col">
                        <span className="text-sm tracking-normal text-left text-black">WELCOME, <span className="font-bold">{user?.profileName?.toUpperCase()}</span></span>
                        <span className="font-sans tracking-normal font-medium text-xs text-left text-slate-500 pt-1">{audLocation} Audition - Round {round} | {adminName} - {adminDesignation} - IMM</span>
                    </div>
                    &nbsp;
                    <div className="grow">&nbsp;</div>
                    <a href={meetingLink} target="_blank" className="btn-logout pr-4">MEETING LINK</a>
                    <span className="btn-logout" onClick={logout}>LOGOUT</span>
                </div>
            )}
        </>
    )
}

export default PrivateHeader;
