import React, { useState } from "react";
import PrivateHeader from "../components/PrivateHeader";
import ChatModule from "../components/chat/ChatModule";
import { useFirebase } from "../providers/FirebaseProvider";
import { useAuth } from "../providers/AuthProvider";
import { MsgType } from "../types";
import { useParams } from "react-router-dom";
import fsmJson from './../mock/f2m.json';
import ffJson from './../mock/ff.json';
import f2fJson from './../mock/f2f.json';

const Portfolios = {
    F2M: {
        type: 'f2m',
        data: fsmJson,
    },
    FF: {
        type: 'ff',
        data: ffJson,
    },
    F2F: {
        type: 'f2f',
        data: f2fJson,
    },
    T1: {
        type: 'solo_t1',
        data: [
            { name: "1.jpg" },
            { name: "2.jpg" },
            { name: "3.jpg" },
            { name: "4.png" },
            { name: "5.png" },
            { name: "6.png" },
            { name: "7.jpg" },
            { name: "8.jpg" },
            { name: "9.jpg" },
            { name: "10.jpg" },
            { name: "11.jpg" },
            { name: "12.jpg" },
            { name: "13.jpg" },
            { name: "14.jpg" },
            { name: "15.jpg" },
            { name: "16.jpg" },
            { name: "17.jpg" },
            { name: "18.jpg" },
            { name: "19.jpg" },
            { name: "20.jpg" },
            { name: "21.jpg" },
            { name: "22.jpg" },
            { name: "23.jpg" },
            { name: "24.jpg" },
            { name: "25.jpg" },
            { name: "26.jpg" },
            { name: "27.png" },
            { name: "28.png" },
            { name: "29.png" },
            { name: "30.png" },
            { name: "31.png" },
            { name: "32.png" },
            { name: "33.png" },
            { name: "34.png" },
            { name: "35.png" }
        ]
    },
    C1: {
        type: 'couple_s1',
        data: [
            { name: "1.jpg" },
            { name: "2.jpg" },
            { name: "3.jpg" },
            { name: "4.jpg" },
            { name: "5.jpg" },
            { name: "6.jpg" },
            { name: "7.jpg" },
            { name: "8.jpg" },
            { name: "9.jpg" },
            { name: "10.jpg" },
            { name: "11.jpg" },
            { name: "12.jpg" },
            { name: "13.jpg" },
            { name: "14.jpg" },
            { name: "15.jpg" },
            { name: "16.jpg" },
            { name: "17.jpg" },
            { name: "18.jpg" },
            { name: "19.jpg" },
            { name: "20.jpg" },
            { name: "21.jpg" },
            { name: "22.jpg" },
            { name: "23.jpg" },
            { name: "24.jpg" },
            { name: "25.jpg" },
            { name: "26.jpg" },
            { name: "27.jpg" },
            { name: "28.jpg" },
            { name: "29.jpg" },
            { name: "30.jpg" },
            { name: "31.png" },
            { name: "32.jpg" },
            { name: "33.jpg" },
            { name: "34.jpg" },
            { name: "35.jpg" },
            { name: "36.jpg" },
            { name: "37.jpg" },
            { name: "38.jpg" },
            { name: "39.jpg" },
            { name: "40.jpg" },
            { name: "41.png" },
            { name: "42.png" },
            { name: "43.png" },
            { name: "44.png" },
            { name: "45.jpg" }
        ]
    }
}

const AdminHome = () => {
    const { audSessionId } = useParams();
    const [color, setColor] = useState('blue');
    const [text, setText] = useState('');

    const portfolio = Portfolios?.F2F;
    const imgBase = `https://imm-audition.s3.ap-south-1.amazonaws.com/${portfolio.type}/`;

    const { sendAuditionMessage } = useFirebase();
    const { user } = useAuth();
    const { sessionId: senderId, profileName: senderName } = user || {};

    const a1 = []; const a3 = [];
    const a2 = []; const a4 = [];

    portfolio.data.forEach((fileName, idx) => {
        const itr = idx + 1;
        // const imgTmp = (() => {
        //     if (idx < 10) return `${imgBase}00`
        //     if (idx < 100) return `${imgBase}0`
        //     return imgBase;
        // })();
        // const imgTmp = idx < 10 ? `${imgBase}0` : imgBase;
        if (itr % 4 == 1) {
            // a1.push(`${imgTmp}${itr}.png`);
            a1.push(`${imgBase}${fileName?.name}`);
        }
        if (itr % 4 == 2) {
            a2.push(`${imgBase}${fileName?.name}`);
            // a2.push(`${imgTmp}${itr}.png`);
        }
        if (itr % 4 == 3) {
            a3.push(`${imgBase}${fileName?.name}`);
            // a3.push(`${imgTmp}${itr}.png`);
        }
        if (itr % 4 == 0) {
            a4.push(`${imgBase}${fileName?.name}`);
            // a4.push(`${imgTmp}${itr}.png`);
        }
    })

    const onDblClick = (img) => {
        console.log('On Selected', img);
        sendAuditionMessage(img, audSessionId, senderId, senderName, MsgType.SlideShow)
    }

    const handleSendAudInfo = (info, type = MsgType.Info) => {
        console.log('Sending Info Text', { info, audSessionId, senderId, senderName, type })
        sendAuditionMessage(info, audSessionId, senderId, senderName, type)
    }

    const sendMsg = () => {
        sendAuditionMessage({ color, text }, audSessionId, senderId, senderName, MsgType.Info)
    }

    return (
        <>
            <div className="container mx-auto">
                <PrivateHeader minimal />

                <div className="grid w-full h-full grid-cols-8 border border-solid border-2 border-slate-100 rounded-sm shadow-sm" style={{ height: 'calc(100vh - 160px)' }}>
                    <div className="col-span-6 bg-white overflow-auto p-2">
                        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                            <div className="grid gap-4">
                                {a1?.map((img, idx) => (
                                    <div key={idx}>
                                        <img onDoubleClick={() => onDblClick(img)} className="h-auto max-w-full rounded-lg" src={img} alt="" />
                                    </div>
                                ))}
                            </div>
                            <div className="grid gap-4">
                                {a2?.map((img, idx) => (
                                    <div key={idx}>
                                        <img onDoubleClick={() => onDblClick(img)} className="h-auto max-w-full rounded-lg" src={img} alt="" />
                                    </div>
                                ))}
                            </div>
                            <div className="grid gap-4">
                                {a3?.map((img, idx) => (
                                    <div key={idx}>
                                        <img onDoubleClick={() => onDblClick(img)} className="h-auto max-w-full rounded-lg" src={img} alt="" />
                                    </div>
                                ))}
                            </div>
                            <div className="grid gap-4">
                                {a4?.map((img, idx) => (
                                    <div key={idx}>
                                        <img onDoubleClick={() => onDblClick(img)} className="h-auto max-w-full rounded-lg" src={img} alt="" />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-span-2 bg-white grow">
                        <div className="p-2 grow border-solid border-2 border-slate-100 grid grid-cols-2 gap-2" style={{ minHeight: 160 }}>
                            <button className="relax mb-1 p-2 cursor-pointer bg-green-500 hover:bg-green-600 active:bg-green-700 focus:outline-none focus:ring focus:ring-green-300 text-white rounded-sm shadow-sm" onClick={() => handleSendAudInfo({ color: 'green', text: 'RELAX' })}>RELAX</button>
                            <button className="relax mb-1 p-2 cursor-pointer bg-green-500 hover:bg-green-600 active:bg-green-700 focus:outline-none focus:ring focus:ring-green-300 text-white rounded-sm shadow-sm" onClick={() => handleSendAudInfo({ color: 'green', text: 'KEEP BODY CLOSER' })}>K.CLSR</button>
                            <button className="relax mb-1 p-2 cursor-pointer bg-green-500 hover:bg-green-600 active:bg-green-700 focus:outline-none focus:ring focus:ring-green-300 text-white rounded-sm shadow-sm" onClick={() => handleSendAudInfo({ color: 'green', text: 'HOLD MORE FIRMLY' })}>H.FIRM</button>
                            <button className="mb-1 p-2 cursor-pointer bg-yellow-500 hover:bg-yellow-600 active:bg-yellow-700 focus:outline-none focus:ring focus:ring-yellow-300 text-white rounded-sm shadow-sm" onClick={() => handleSendAudInfo({ color: 'yellow', text: 'HOLD' })}>HOLD</button>
                            <button className="mb-1 p-2 cursor-pointer bg-red-500 hover:bg-red-600 active:bg-red-700 focus:outline-none focus:ring focus:ring-red-300 text-white rounded-sm shadow-sm" onClick={() => handleSendAudInfo({ color: 'red', text: 'RETRY' })}>RETRY</button>
                            <button className="mb-1 p-2 cursor-pointer bg-red-500 hover:bg-red-600 active:bg-red-700 focus:outline-none focus:ring focus:ring-red-300 text-white rounded-sm shadow-sm" onClick={() => handleSendAudInfo({ color: 'green', text: 'TIME TO POSE' })}>S.POSING</button>
                            <button className="mb-1 p-2 cursor-pointer bg-red-500 hover:bg-red-600 active:bg-red-700 focus:outline-none focus:ring focus:ring-red-300 text-white rounded-sm shadow-sm" onClick={() => handleSendAudInfo({ color: 'red', text: 'UNCOVER CAM' })}>UNCOVER</button>
                            <button className="relax mb-1 p-2 cursor-pointer bg-violet-500 hover:bg-violet-600 active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300 text-white rounded-sm shadow-sm" onClick={() => handleSendAudInfo({ action: true, type: 'toggleCam' }, MsgType.Action)}>STP CAM</button>
                            <button className="mb-0 p-2 cursor-pointer bg-violet-500 hover:bg-violet-600 active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300 text-white rounded-sm shadow-sm" onClick={() => handleSendAudInfo({ action: false, type: 'toggleCam' }, MsgType.Action)}>R.CAM</button>
                            <button className="mb-0 p-2 cursor-pointer bg-red-500 hover:bg-red-600 active:bg-red-700 focus:outline-none focus:ring focus:ring-red-300 text-white rounded-sm shadow-sm" onClick={() => handleSendAudInfo({ color: 'red', text: 'logout', type: 'action' })}>LOGOUT</button>
                            <button className="mb-0 p-2 cursor-pointer bg-red-500 hover:bg-red-600 active:bg-red-700 focus:outline-none focus:ring focus:ring-red-300 text-white rounded-sm shadow-sm" onClick={() => handleSendAudInfo({ color: 'red', text: 'showPreview', type: 'action' }, MsgType.Action)}>SHOW PREVIEW</button>
                            <button className="mb-0 p-2 cursor-pointer bg-red-500 hover:bg-red-600 active:bg-red-700 focus:outline-none focus:ring focus:ring-red-300 text-white rounded-sm shadow-sm" onClick={() => handleSendAudInfo({ color: 'red', text: 'hidePreview', type: 'action' }, MsgType.Action)}>HIDE PREVIEW</button>
                        </div>
                        <div className="flex w-full grow">
                            <input className="shrink" onChange={e => setColor(e.currentTarget.value)} id="info-color" name="info-color" placeholder="color" />
                            <input className="grow" id="info-msg" name="info-msg" onChange={e => setText(e.currentTarget.value)} placeholder="text" />
                            <button onClick={() => sendMsg()}>Send</button>
                        </div>
                        <div>
                            <ChatModule adminMode height={'64vh'} sessionId={audSessionId} senderId={'701447'} />
                        </div>
                    </div>
                    <div className="bg-yellow-50 bg-green-50 bg-red-50 bg-violet-50 text-green-700 text-red-700 text-yellow-700 text-violet-700" style={{ visibility: 'hidden' }} />
                </div>
            </div>
        </>
    )
}

export default AdminHome;