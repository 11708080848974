import React, { useEffect, useState } from "react";
import ReactFullpage from '@fullpage/react-fullpage';
import ImageViewer from "react-simple-image-viewer";

import { useForm } from "react-hook-form";

import Footer from "../components/Footer";
import AuditionOverview from "../components/AuditionOverview";
import { AUDITION_INSTRUCTIONS, AuditionIndexMap, AuditionMap, COUPLE_ACTIVITIES } from "../constants";
import PrivateHeader from "../components/PrivateHeader";
import { useAuth } from "../providers/AuthProvider";
import { Navigate } from "react-router-dom";
import { useFirebase } from "../providers/FirebaseProvider";
import useMessages from "../hooks/useMessages";
import WebCapture from "../components/WebCapture";

export const AUDITION_KEY = 'imm_aud_key';
const MESSAGE_PATH = 'messages/fash_aud_chat_mode';
const TOTAL_SESSIONS = 6;

const AuditionInterview = () => {
    const [caCount, setCACount] = useState(0);
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors, },
    } = useForm();
    let api;

    // WebCam
    const webcamRef = React.useRef(null);
    const [imgSrc, setImgSrc] = React.useState(null);

    const [showSlideshow, setShowSlideshow] = useState(false);
    // const [interviewKey, setInterviewKey] = useState(localStorage.getItem(AUDITION_KEY || 0));
    const interviewKey = localStorage.getItem(AUDITION_KEY) || 0;
    const [isLastSlide, setIsLastSlide] = useState(false);

    const { listenToMessages, sendMessage } = useFirebase();
    const messages = useMessages(MESSAGE_PATH);


    const onMessageReceived = (msg) => {
        console.log('Msg from server', msg);
    }

    useEffect(() => {
        listenToMessages(MESSAGE_PATH, onMessageReceived)
    }, [listenToMessages])

    const sessionName = AuditionIndexMap?.[interviewKey];
    const nowShowing = AuditionMap?.[sessionName];
    const sessionType = nowShowing?.type;
    const instructions = AUDITION_INSTRUCTIONS?.[sessionType ?? 'casual'];

    const { consent, reviewed, logout } = useAuth();

    const onSubmit = (data) => {
        console.log(data);
    }

    const closeImageViewer = () => {

    }

    const onSlideChange = (section, origin, destination, direction, trigger) => {
        const { isLast } = destination || {};
        setIsLastSlide(isLast);
        setImgSrc(null);
    }

    const handleAuditionComplete = () => {
        if (+interviewKey < TOTAL_SESSIONS - 1) {
            localStorage.setItem(AUDITION_KEY, +interviewKey + 1);
            window.location.reload();
        } else {
            logout();
        }
    }

    const handleOnCapture = (wcIndex) => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImgSrc({
            ...imgSrc,
            [wcIndex]: imageSrc,
        });
    }

    if (!consent) {
        return <Navigate to='/audition/consent' />
    }

    if (!reviewed) {
        return <Navigate to='/audition/home' />
    }

    const sendMsg = () => {
        sendMessage(MESSAGE_PATH, 'Acknowledged!')
    }

    const handleOnNext = () => {
        api.moveSlideRight();
    }

    const nextActivity = () => {
        setCACount((prev, cur) => {
            if (prev === 26 - 1) { setIsLastSlide(true) }
            return prev + 1
        })
    }

    return (
        <>
            <PrivateHeader minimal showDetails />
            <main className="main h-full onsite-wrap">
                <div className="grid grid-cols-12 gap-4 h-full onsite-wrap-cover">
                    <div className="grid-item col-span-2 bg-white rounded-md relative shadow-md p-4">
                        <AuditionOverview
                            activeStep={nowShowing?.progess - 1}
                            isLastSlide={isLastSlide}
                            onAuditionComplete={handleAuditionComplete}
                            moveNext={handleOnNext}
                        />
                    </div>
                    <div className="grid-item col-span-7 bg-white rounded-md relative shadow-md">
                        <ReactFullpage
                            keyboardScrolling={false}
                            scrollingSpeed={600}
                            waterEffect={false}
                            loopHorizontal={false}
                            responsiveHeight={400}
                            onSlideLeave={onSlideChange}
                            onScrollOverflow={() => console.log('On Overflow')}
                            render={({ state, fullpageApi }) => {
                                api = fullpageApi;
                                fullpageApi?.setKeyboardScrolling(true, 'left, right');
                                return (
                                    <ReactFullpage.Wrapper>
                                        <div className="section" id="section0">
                                            {nowShowing?.images?.map((image, idx) => (
                                                <div key={idx} className="slide" data-anchor={`slide${idx + 1}`}>
                                                    <img className="img-poses aspect-auto object-contain" src={`https://imm-audition.s3.ap-south-1.amazonaws.com/${image}`} />
                                                </div>
                                            ))}
                                        </div>
                                    </ReactFullpage.Wrapper>
                                );
                            }}
                        />
                        <div className="wm-wrap">
                            &nbsp;
                        </div>

                        {sessionType === 'couple_activity' && (
                            <div className="activity-wrap rounded-md items-center content-center justify-center">
                                <h4 className="text-white p-16 text-4xl uppercase text-center" style={{ lineHeight: '3.5rem', padding: '5rem' }}>{COUPLE_ACTIVITIES?.[caCount]?.activity}</h4>
                            </div>
                        )}
                    </div>
                    <div className="grid-item col-span-3 gap-2 flex">
                        <WebCapture moveNext={handleOnNext} nextActivity={nextActivity} instructions={instructions} sessionType={sessionType} showPrefInput={COUPLE_ACTIVITIES?.[caCount]?.input === true} />
                    </div>
                </div>
            </main>
            <Footer hideBorder />
        </>
    )
}

export default AuditionInterview;
