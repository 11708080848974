import React from "react";
import { useForm } from "react-hook-form";

import Footer from "../components/Footer";
import PrivateHeader from "../components/PrivateHeader";
import { Navigate, useNavigate } from "react-router-dom";
import { AUTH_SESSION_AUDITION_REVIEW } from "../constants";
import { useAuth } from "../providers/AuthProvider";

const AuditionHome = () => {
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors, },
    } = useForm();

    const navigate = useNavigate();
    const { reviewed, user } = useAuth();
    const { audition } = user || {};
    console.log(audition)

    const { nooutfit = false, disableCouple = false } = audition || {};

    const to = user?.onsite === true ? '/onsite/interview' : '/virtual/audition/interview'

    const onSubmit = () => {
        localStorage.setItem(AUTH_SESSION_AUDITION_REVIEW, true);
        navigate(to);
    }

    if (reviewed) {
        return <Navigate to={to} />
    }

    return (
        <div style={{ paddingTop: 32, display: 'flex', flexDirection: 'column', minHeight: 'calc(100vh - 32px)' }}>
            <div className="page-wrap" style={{ flexGrow: 1 }}>
                <PrivateHeader minimal />

                <div className="consent-form">
                    <h3 style={{ fontWeight: 'bold' }}>Review & Start Audition Interview</h3>
                    <div className="consent-data">
                        <div className="consent-list">

                            <div style={{ overflowY: 'scroll' }}>
                                <ul className="consent-items">
                                    {audition.round == '1' && (
                                        <>
                                            <li>
                                                <h4>Physical & Modelling Fitness: </h4>
                                                <span>
                                                    In this session, you will be assessed on your physical fitness and overall body composition. We will evaluate your posture, body proportions, and overall physical presence. Be prepared to showcase your flexibility, balance, and strength through a series of basic exercises.
                                                </span>
                                            </li>
                                            <li>
                                                <h4>Ramp Walk Evaluation: </h4>
                                                <span>
                                                    In this session, you will be assessed on your ramp walk in various strides. You will be asked to demonstrate different walking styles, including casual, confident, and elegant strides.
                                                </span>
                                            </li>
                                        </>
                                    )}
                                    <li>
                                        <h4>Casual Outfit - Solo Poses (w) 3 Outfit Variations: </h4>
                                        <span>
                                            Embrace your individuality as you showcase your style and personality through a series of solo poses. This session invites you to bring forth your unique flair, portraying the ease and versatility of casual fashion. Let your confidence shine as you navigate through a collection of relaxed yet impactful poses, creating an engaging visual narrative. Casual Outfit 1 - Solo Poses is the perfect canvas for you to exhibit your modeling prowess with a touch of laid-back sophistication.
                                        </span>
                                    </li>
                                    {audition?.bikini === true && (
                                        <li>
                                            <h4>Bikini Outfit - Solo Poses (w) 2 Outfit Variations:</h4>
                                            <span>
                                                This session is a celebration of your individuality and swimwear style, providing a platform for you to express your unique charm. Unleash a series of solo poses that showcase your comfort and versatility in swimwear fashion, radiating both strength and glamour.
                                            </span>
                                        </li>
                                    )}
                                    {audition?.topless === true && (
                                        <li>
                                            <h4>Topless - Solo Poses (w) 2 Variations:</h4>
                                            <span>
                                                Explore the artistry of self-expression in the Topless - this session focussed to embrace the freedom and confidence that comes with baring your upper body. Display your individuality through a series of carefully curated solo poses, highlighting your comfort, poise, and natural beauty. The Topless - Solo Poses session is designed to celebrate your self-assurance and artistic expression, providing a platform for you to showcase your unique modeling style in an empowered and tasteful manner.
                                            </span>
                                        </li>
                                    )}
                                    {nooutfit === true && (
                                        <li>
                                            <h4>Natural Look - No Outfit:</h4>
                                            <span>
                                                Discover the essence of natural beauty in the No Outfit - Natural Look session. This experience is crafted to emphasize the authenticity, confidence, raw emotion, and grace that define your presence. Through a thoughtful selection of solo poses, this session highlights your natural form, focusing on subtlety and sophistication to capture the purity of your expression and confidence. The No Outfit - Natural Look session aims to celebrate your individuality and strength, allowing you to portray your modeling talent with depth, elegance, and artistry in its purest form.
                                            </span>
                                        </li>
                                    )}
                                    {audition?.round != '1' && !disableCouple && (
                                        <>
                                            <li>
                                                <h4>Casual Outfit - Couple Poses (w) 2 Outfit Variations: :</h4>
                                                <span>
                                                    Couple Poses audition session, where the focus is on the chemistry and connection between two individuals. In this session, you'll have the opportunity to wear casual attire and collaborate with a partner or a modeling photographer for a series of captivating couple poses. Let the genuine expressions of companionship and shared style unfold, creating a visual narrative that speaks to the ease and comfort of casual fashion in a relational context. The Casual Outfit - Couple Poses session encourages a dynamic and expressive portrayal of fashion and connection, offering a unique and delightful audition experience.
                                                </span>
                                            </li>
                                            {audition?.bikini === true && (
                                                <li>
                                                    <h4>Bikini Outfit - Couple Poses (w) 2 Outfit Variations:</h4>
                                                    <span>
                                                        The spotlight is on the synergy and harmony between two individuals. This session invites you to don stunning bikini ensembles while collaborating with a partner or a modeling photographer for a series of captivating couple poses. Showcase the natural chemistry and shared style, creating a visual narrative that celebrates the beauty and confidence found in swimwear fashion within a relational context. The Bikini Outfit - Couple Poses session offers a unique opportunity for a dynamic and expressive portrayal of fashion and connection, promising a captivating and unforgettable audition experience.
                                                    </span>
                                                </li>
                                            )}
                                            {audition?.topless === true && (
                                                <li>
                                                    <h4>Topless - Couple Poses (w) 2 Variations:</h4>
                                                    <span>
                                                        This session focusses on opportunity encourages a celebration of intimacy and shared confidence between two individuals. In this session, you are invited to explore topless poses while collaborating seamlessly with a partner or a skilled modeling photographer. Together, create a series of tasteful and expressive couple poses that reflect the natural beauty and harmony of your connection. The Topless Outfit - Couple Poses session is designed for a unique and empowering portrayal of fashion and companionship, promising a memorable audition experience filled with authenticity and grace.
                                                    </span>
                                                </li>
                                            )}
                                        </>
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div className="submissions-validate" style={{ paddingTop: 32 }}>
                            <button onClick={onSubmit} id="submissions-submit" type="submit" className="submit">START AUDITION</button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AuditionHome;
